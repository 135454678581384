import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Papa from "papaparse";
import Navbar from "./Navbar";

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filters, setFilters] = useState({ brand: "", category: "", type: "", search: "" });

  const location = useLocation();
  const navigate = useNavigate();

    // Parse query parameters from the URL
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const brand = queryParams.get("brand") || "";
        const category = queryParams.get("category") || "";
        setFilters({ ...filters, brand, category });
    }, [location.search]);

    // Fetch data from Google Sheets
    useEffect(() => {
        const fetchData = async () => {
        try {
            // Fetch products CSV
            const productsRes = await fetch(
            `https://docs.google.com/spreadsheets/d/e/2PACX-1vSeGR0MPoim7lt6jp5u4Whh0GogXmHNviqpvUzLR7Ff9Wu3-AWCswpMjB3MrFiuafMTovXsAUUJG1Ib/pub?gid=0&single=true&output=csv`
            );
            const productsCsv = await productsRes.text();
            const productsData = Papa.parse(productsCsv, { header: true }).data;

            // Fetch brands CSV
            const brandsRes = await fetch(
            `https://docs.google.com/spreadsheets/d/e/2PACX-1vT4oADbCGa9J-czcUjcncJNVHKGza77CQHDu536gOUIsWXlZpyIlNX7XiHp9On5zGJybzb1vlApZkkZ/pub?gid=29616083&single=true&output=csv`
            );
            const brandsCsv = await brandsRes.text();
            const brandsData = Papa.parse(brandsCsv, { header: true }).data.map(row =>
            Object.fromEntries(
                Object.entries(row).map(([key, value]) => [key.toLowerCase(), value?.toLowerCase()])
            )
            );

            setProducts(productsData);
            setFilteredProducts(productsData); // Initial filter matches all products
            setBrands(brandsData);
        } catch (error) {
            console.error("Error fetching data from Google Sheets:", error);
        }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const filtered = products.filter((product) => {
        const matchesBrand = !filters.brand || product.brand === filters.brand;
        const matchesCategory = !filters.category || product.category === filters.category;
        const matchesType = !filters.type || product.type === filters.type;
        const matchesSearch =
            !filters.search ||
            Object.values(product)
            .join(" ")
            .toLowerCase()
            .includes(filters.search.toLowerCase());

        return matchesBrand && matchesCategory && matchesSearch && matchesType;
        });

        setFilteredProducts(filtered);
    }, [filters, products]);

  // Update filters and query parameters
  const handleFilterChange = (key, value) => {
    const updatedFilters = { ...filters, [key]: value };
    setFilters(updatedFilters);

    // Update query parameters in the URL
    const queryParams = new URLSearchParams(updatedFilters).toString();
    navigate(`?${queryParams}`);
  };

  return (
    <>
        <Navbar />
        <div className="p-4">
        <h2 className="text-3xl font-semibold mb-4 text-center">Glow+24 Products</h2>

        {/* Search Bar */}
        <input
            type="text"
            placeholder="Search for products..."
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
            className="p-2 border rounded mb-4 w-full"
        />

        {/* Filters */}
        <div className="flex flex-wrap gap-4 mb-6">
            {/* Brand Filter */}
            <select
            value={filters.brand}
            onChange={(e) => handleFilterChange("brand", e.target.value)}
            className="p-2 border rounded"
            >
            <option value="">All Brands</option>
            {brands.map((brand) => (
                <option key={brand.id} value={brand.brand_name}>
                {brand.brand_name}
                </option>
            ))}
            </select>

            {/* Category Filter */}
            <select
            value={filters.category}
            onChange={(e) => handleFilterChange("category", e.target.value)}
            className="p-2 border rounded"
            >
            <option value="">All Categories</option>
            {Array.from(new Set(products.map((product) => product.category))).map((category, index) => (
                <option key={index} value={category}>
                {category}
                </option>
            ))}
            </select>

            <select
            value={filters.type}
            onChange={(e) => handleFilterChange("type", e.target.value)}
            className="p-2 border rounded"
            >
            <option value="">All Types</option>
            {Array.from(new Set(products.map((product) => product.type))).map((type, index) => (
                <option key={index} value={type}>
                {type}
                </option>
            ))}
            </select>
        </div>

        {/* Products Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {filteredProducts.map((product) => (
            <div
                key={product.id}
                className="border rounded-lg shadow-md p-4 bg-white hover:shadow-xl transition-shadow duration-300"
            >
                <div className="relative w-full h-48 overflow-hidden rounded-md mb-4">
                <img
                    src={product.image}
                    alt={product.title}
                    className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                />
                </div>
                <div className="text-center">
                <h3 className="text-xl font-semibold mb-2 text-gray-800">{product.title}</h3>
                <p className="text-sm text-gray-500 mb-1">{product.description}</p>
                <p className="text-sm text-gray-500 mb-1">Category: {product.category}</p>
                <p className="text-sm text-gray-500 mb-3">Brand: {product.brand}</p>
                <p className="text-lg font-bold text-primary">Rp. {product.price}</p>
                </div>
            </div>
            ))}
        </div>
        </div>
    </>
  );
};

export default ProductsPage;
