import React, { useState, useEffect } from "react";
import { ref, listAll, getDownloadURL, updateMetadata, getMetadata } from "firebase/storage";
import { storage } from "./firebase";

const ReorderImages = ({ onImagesReordered }) => {
  const [images, setImages] = useState([]);
  const [imageRefs, setImageRefs] = useState([]);
  const [imageOrder, setImageOrder] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
        const folderRef = ref(storage, "uploads");
        const res = await listAll(folderRef);

        // Get image URLs and store references
        const urls = await Promise.all(res.items.map((itemRef) => getDownloadURL(itemRef)));
        setImages(urls);
        setImageRefs(res.items);

        // Fetch image order from metadata (assuming you have 'order' metadata set for each image)
        const orderMetadata = await Promise.all(
            res.items.map(async (itemRef, index) => {
            try {
                const metadata = await getMetadata(itemRef); // Use getMetadata on itemRef directly
                // Use metadata.order if it exists, otherwise default to the image's index (image_0, image_1, etc.)
                return metadata.customMetadata?.order || `image_${index}`;
            } catch (error) {
                console.error("Error fetching metadata for image:", error);
                return `image_${index}`; // Default to image_x if metadata fetch fails
            }
            })
        );

        setImageOrder(orderMetadata); // Store the order in state
        };

        fetchImages();
    }, []);

//   useEffect(() => {
//     const fetchImages = async () => {
//       const folderRef = ref(storage, "uploads");
//       const res = await listAll(folderRef);

//       // Get image URLs and store references
//       const urls = await Promise.all(res.items.map((itemRef) => getDownloadURL(itemRef)));
//       setImages(urls);
//       setImageRefs(res.items);

//       // Fetch image order from metadata
//       const orderMetadata = await Promise.all(
//         res.items.map(async (itemRef, index) => {
//           try {
//             const metadata = await itemRef.getMetadata();
//             // Use metadata.order if available, else fallback to 'image_x'
//             return metadata.customMetadata?.order || `image_${index}`;
//           } catch (error) {
//             console.error("Error fetching metadata for image:", error);
//             return `image_${index}`; // Default order if error occurs
//           }
//         })
//       );
//       setImageOrder(orderMetadata); // Store the order in state
//     };

//     fetchImages();
//   }, []);

  // Sort images based on the 'order' metadata, with a fallback for invalid 'order' values
  const sortedImages = images
    .map((url, index) => ({ url, order: imageOrder[index] })) // Pair image URLs with their order
    .sort((a, b) => {
      const orderA = a.order && a.order.split("_")[1]; // Safely extract numeric part of order (e.g., from 'image_0')
      const orderB = b.order && b.order.split("_")[1]; // Safely extract numeric part of order (e.g., from 'image_1')

      // If either order is undefined, treat it as the last order (place them at the end)
      if (orderA === undefined) return 1;
      if (orderB === undefined) return -1;

      return parseInt(orderA) - parseInt(orderB); // Sort by numeric order
    })
    .map(item => item.url); // Extract sorted image URLs

  const moveImage = async (index, direction) => {
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= images.length) return;

    // Reorder images and refs
    const reorderedImages = [...images];
    const reorderedRefs = [...imageRefs];
    [reorderedImages[index], reorderedImages[newIndex]] = [
      reorderedImages[newIndex],
      reorderedImages[index],
    ];
    [reorderedRefs[index], reorderedRefs[newIndex]] = [
      reorderedRefs[newIndex],
      reorderedRefs[index],
    ];

    setImages(reorderedImages);
    setImageRefs(reorderedRefs);

    try {
      // Update metadata for each image
      await Promise.all(
        reorderedRefs.map(async (itemRef, idx) => {
          const metadata = {
            customMetadata: {
              order: `image_${idx}`,
            },
          };

          await updateMetadata(itemRef, metadata);
          console.log(`Updated metadata for ${itemRef.name}: order = image_${idx}`);
        })
      );

      console.log("Metadata updated successfully!");
    } catch (error) {
      console.error("Error updating metadata:", error);
    }

    // Notify parent about reordering (if necessary)
    if (onImagesReordered) onImagesReordered(reorderedImages);
  };

  return (
    <div className="flex items-center justify-center flex-col">
      <h3 className="text-xl font-bold mb-4">Reorder Images</h3>
      <div className="grid gap-4">
        {sortedImages.map((image, index) => (
          <div key={index} className="flex items-center gap-4">
            <img src={image} alt={`Thumbnail ${index}`} className="w-20 h-20 object-cover" />
            <button
              onClick={() => moveImage(index, -1)}
              className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600 disabled:bg-gray-300"
              disabled={index === 0}
            >
              Move Up
            </button>
            <button
              onClick={() => moveImage(index, 1)}
              className="bg-green-500 text-white py-1 px-3 rounded hover:bg-green-600 disabled:bg-gray-300"
              disabled={index === images.length - 1}
            >
              Move Down
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReorderImages;
