import { faShop, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import ImageCarousel from "./ImageCarousel";

const HomePage = () => {
    const navigate = useNavigate();

    const scrollToSection = (section) => {
        
        // Check if the current path is not '/'
        if (window.location.pathname !== '/') {
          // Navigate to the homepage first
          navigate('/');
      
          // Wait for the page to load and then scroll
          setTimeout(() => {
            const element = document.getElementById(section);
            element?.scrollIntoView({ behavior: 'smooth' });
          }, 100);
        } else {
          // If already on the homepage, directly scroll to the section
          const element = document.getElementById(section);
          element?.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <>
            <Navbar />
            <div className="bg-white text-gray-800">
            {/* Hero Section */}
            <ImageCarousel />
            <section className="grid lg:grid-cols-5 col-span-1 lg:p-12">
                <div className="lg:col-span-2 col-span-5 w-full p-6 lg:text-left text-center">
                    <h1 className="text-5xl font-bold text-gray-800 mb-4">
                        Discover Your Perfect Health & Beauty Essentials
                    </h1>
                    <p className="text-lg text-gray-600 mb-6">
                        Experience a curated collection of top products for a healthier, more radiant you — all in one place.
                    </p>
                    <div className="flex gap-4 lg:justify-normal justify-center">
                        <Link to="/products">
                        <button className="hover:bg-gradient-to-r from-primary to-accentBlue bg-transparent transition hover:text-white text-primary border border-2 border-primary px-6 py-2 rounded-full">
                            <FontAwesomeIcon icon={faShop} /> View Products
                        </button>
                        </Link>
                        <button onClick={() => scrollToSection('contact')} className="bg-transparent hover:bg-gradient-to-r from-secondary to-accentPink hover:text-white transition border-2 border-secondary text-secondary px-6 py-2 rounded-full">
                            Contact Us
                        </button>
                        
                    </div>
                    <div className="mt-6 text-lg font-semibold">
                        {[...Array(5)].map((_, index) => (
                            <FontAwesomeIcon className="text-secondary" key={index} icon={faStar} />
                        ))}
                        <br></br>
                        "Glow+24 has a really complete set of products and I can always come here whether in the night or morning!"
                        <br></br>
                        {[...Array(4)].map((_, index) => (
                            <FontAwesomeIcon className="mt-4 text-secondary" key={index} icon={faStar} />
                        ))}
                        <br></br>
                        "Would definitely come back to buy more products with how high quality everything is."
                    </div>
                </div>
                <div className="lg:col-span-3 col-span-5 p-6">
                <img
                    src={"/images/hero.png"}
                    alt="Glow+24 hero image"
                    className="w-full h-auto rounded-md shadow-lg shadow-accentPink"
                />
                </div>
            </section>

            {/* About Us Section */}
            <section className="bg-white text-center px-6 py-12" id="about">
                <h2 className="text-3xl font-semibold text-gray-800 mb-6">About Us</h2>
                <p className="text-lg text-gray-600 mb-6">
                GLOW+24 is a leading health and beauty retail pharmacy that offer a wide range of products related to personal care, wellness, and medical needs including medications, skincare, cosmetics, vitamins, supplements, etc. These stores aim to provide a convenient one-stop-shop for customers’ health and beauty needs. GLOW+24 will be the first 24 Hours Health & Beauty Retail Pharmacy in a Mall.
                </p>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
                <div className="col-span-1 bg-secondary p-6 rounded-lg shadow-md">
                    <h3 className="text-xl text-white font-semibold mb-4">Our Vision</h3>
                    <p className="text-white">
                    To be the trusted partner in health & beauty, empowering individuals to live their lives through personalized care, expert advice, and innovative products
                    <br></br><br></br>
                    To be the ultimate destination where health meets beauty, offering unparalleled product diversity and customer-centric services that enrich lives and foster well-being
                    </p>
                </div>
                <div className="col-span-1 bg-primary p-6 rounded-lg shadow-md">
                    <h3 className="text-xl text-white font-semibold mb-4">Our Mission</h3>
                    <p className="text-white">
                    To be the first 24 hours Health & Beauty Store in a Mall
                    <br></br><br></br>
                    Health & Beauty Store with a wide variety of health and beauty products
                    <br></br><br></br>
                    To empowered individuals to live beautifully inside and out by providing expert guidance, innovative products, and exceptional experiences that inspire confidence happiness, and vitality

                    </p>
                </div>
                </div>
            </section>

            {/* Glow Solutions Section */}
            <section className="bg-white py-12" id="services">
                <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8 ">
                Glow Solutions
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 px-6">
                <div className="bg-white p-3 rounded-lg shadow-md">
                    <img
                    src={"/images/glowbar.png"}
                    alt="Solution 1"
                    className="w-full object-cover rounded-md mb-4"
                    />
                    <h3 className="text-xl font-semibold mb-2 text-primary">Glow Bar</h3>
                    <p className="text-gray-600">
                    Immerse yourself in our interactive beauty experience. Our tester area invites you to try before you buy, ensuring you find products that enhance your unique beauty and style.
                    </p>
                </div>
                <div className="bg-white p-3 rounded-lg shadow-md">
                    <img
                    src={"/images/pharmacy.png"}
                    alt="Glow Bar"
                    className="w-full object-cover rounded-md mb-4"
                    />
                    <h3 className="text-xl font-semibold mb-2 text-secondary">24 Hours Pharmacy</h3>
                    <p className="text-gray-600">
                    Explore our pharmacy section. From essential vitamins and suplements to over-the-counter medications and wellness products, our knowledgable pharmacist is here to assist you in finding the right solution for your health needs.
                    </p>
                </div>
                <div className="bg-white p-3 rounded-lg shadow-md">
                    <img
                    src={"/images/checkup.png"}
                    alt="Solution 3"
                    className="w-full object-cover rounded-md mb-4"
                    />
                    <h3 className="text-xl font-semibold mb-2 text-primary">Glow Quick Check-Up</h3>
                    <p className="text-gray-600">
                    Prioritize your health with our medical check-up. Our healthcare providers offer comprehensive assessments and consultations to monitor your well-being and address any concerns. From routine screenings to personalized health advice.
                    </p>
                </div>
                <div className="bg-white p-3 rounded-lg shadow-md">
                    <img
                    src={"/images/consultant.png"}
                    alt="Solution 4"
                    className="w-full object-cover rounded-md mb-4"
                    />
                    <h3 className="text-xl font-semibold mb-2 text-secondary">Health & Beauty Consultation</h3>
                    <p className="text-gray-600">
                    Receive personalized guidance and expert advice with our health consultation services. Our knowledgeable pharmacist provide tailored recommendations based on your individual needs, offering insights to help you achieve and maintain optimal health and wellness.
                    </p>
                </div>
                </div>
            </section>

            {/* Contact Section */}
            <section className="bg-white px-6 py-12" id="contact">
                <h2 className="text-3xl font-semibold text-center text-gray-800">
                Contact Us
                </h2>
                <p className="text-center mb-6">Ararasa Mall, Jl. BSD Raya Utama, Lengkong Kulon, Kec. Pagedangan, Kab. Tangerang, Banten 15331</p>
                <div className="flex justify-center gap-6 mb-6">
                {/* Google Maps Embed */}
                <div className="w-full sm:w-1/2 h-64 bg-gray-200 rounded-lg shadow-md">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.8626650080314!2d106.63449077515058!3d-6.281778761489398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fb86ef285f2b%3A0x2372a05b061d99f8!2sArarasa%20BSD!5e0!3m2!1sen!2sus!4v1737263361091!5m2!1sen!2sus" className="h-full w-full" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                </div>
                <div className="text-center flex flex-row items-center justify-center gap-2">
                <a
                    href="https://www.instagram.com/glow24.id/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D#"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block bg-secondary text-white px-6 py-2 rounded-full"
                >
                    <i class="fab fa-instagram"></i> Instagram
                </a>
                <a
                    href="https://wa.me/+6281324354180"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block bg-primary text-white px-6 py-2 rounded-full"
                >
                    <i class="fab fa-whatsapp"></i> WhatsApp
                </a>
                </div>
            </section>

            <footer className="bg-white border-t-2 text-black text-left p-4">
                <p>&copy; 2025 Glow+24. All Rights Reserved.</p>
                <p>Published in 2025</p>
            </footer>
            </div>
        </>
    );
};

export default HomePage;
