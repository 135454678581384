import React from 'react';
import ImageUploader from './ImageUploader';
import ImageCarousel from './ImageCarousel';
import ReorderImages from './ReorderImages';

const DashboardPage = ({ user }) => {
  return (
    <div className="text-center p-4">
      <h1 className="text-2xl">Upload Banner</h1>
      {user ? (
        <>
          <ImageUploader />
          <ImageCarousel />
          <ReorderImages />
        </>
      ) : (
        <p>Please log in to access the content.</p>
      )}
    </div>
  );
};

export default DashboardPage;
