// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAQgOhs5-Wn8vgcaBDKL_yNyBOxt19cvy8",
  authDomain: "glow2024-b7427.firebaseapp.com",
  projectId: "glow2024-b7427",
  storageBucket: "glow2024-b7427.appspot.com",
  messagingSenderId: "146001915409",
  appId: "1:146001915409:web:28a2da7dbae00443240bb0",
  measurementId: "G-KDYPY32R6E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const storage = getStorage(app);

export { auth, storage };