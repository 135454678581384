import React, { useState, useEffect } from "react";
import { storage } from "./firebase";
import { ref, listAll, getDownloadURL, getMetadata } from "firebase/storage";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ImageCarousel() {
  const [images, setImages] = useState([]); // To store image URLs
  const [imageOrder, setImageOrder] = useState([]); // Stores image order from metadata
  const [currentIndex, setCurrentIndex] = useState(0);


    useEffect(() => {
        const fetchImages = async () => {
        const folderRef = ref(storage, "uploads");
        const res = await listAll(folderRef);

        // Get image URLs and store references
        const urls = await Promise.all(res.items.map((itemRef) => getDownloadURL(itemRef)));
        setImages(urls);

        // Fetch image order from metadata (assuming you have 'order' metadata set for each image)
        const orderMetadata = await Promise.all(
            res.items.map(async (itemRef, index) => {
            try {
                const metadata = await getMetadata(itemRef); // Use getMetadata on itemRef directly
                // Use metadata.order if it exists, otherwise default to the image's index (image_0, image_1, etc.)
                return metadata.customMetadata?.order || `image_${index}`;
            } catch (error) {
                console.error("Error fetching metadata for image:", error);
                return `image_${index}`; // Default to image_x if metadata fetch fails
            }
            })
        );

        setImageOrder(orderMetadata); // Store the order in state
        };

        fetchImages();
    }, []);

  // Sort the images based on the order metadata
  const sortedImages = images
    .map((url, index) => ({ url, order: imageOrder[index] })) // Pair each URL with its corresponding order
    .sort((a, b) => {
        const orderA = a.order && a.order.split("_")[1]; // Safely extract the numeric part of 'order' (e.g., 'image_0' becomes '0')
        const orderB = b.order && b.order.split("_")[1]; // Same for 'b'

        // If either order is undefined, treat them as last
        if (orderA === undefined) return 1;
        if (orderB === undefined) return -1;

        return parseInt(orderA) - parseInt(orderB); // Sort by numeric order
    })
    .map(item => item.url); // Extract sorted URLs


    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % sortedImages.length);
        }, 6000); // Change image every 3 seconds
    
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [sortedImages.length]);
    
    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + sortedImages.length) % sortedImages.length);
    };
    
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % sortedImages.length);
    };

  return (
    <div className="relative w-full mx-auto my-8 p-4">
      <div className="flex overflow-x-auto no-scrollbar">
        <div className="relative">
          <div
            className="flex transition-all ease-in-out duration-300"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {sortedImages.map((image, index) => (
              <div key={index} className="flex-shrink-0 w-full h-96 relative">
                <img
                  src={image}
                  alt={`carousel ${index}`}
                  className="w-full h-full object-cover rounded-md"
                />
              </div>
            ))}
          </div>

          {/* Left Arrow */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black text-white p-2 rounded-full hover:bg-gray-600"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>

          {/* Right Arrow */}
          <button
            onClick={handleNext}
            className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black text-white p-2 rounded-full hover:bg-gray-600"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>

      {/* Navigation Dots */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {sortedImages.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`h-2 w-2 rounded-full ${currentIndex === index ? 'bg-blue-500' : 'bg-gray-300'}`}
          />
        ))}
      </div>
    </div>
  );
}

export default ImageCarousel;
