import React, { useState, useEffect } from 'react';
import Papa from "papaparse";
import { useNavigate, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faAngleDown, faBars, faListAlt, faStar, faStarAndCrescent, faStarOfLife, faTag, faX } from '@fortawesome/free-solid-svg-icons';
import { faTags } from '@fortawesome/free-solid-svg-icons/faTags';

const Navbar = () => {
    const [categorySelected, setCategorySelected] = useState("");
    const [brandSelected, setBrandSelected] = useState("");
    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
    const [showBrandDropdown, setShowBrandDropdown] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [products, setProducts] = useState([]);
    const [brands, setBrands] = useState([]);
    const [state, setState] = useState(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (!event.target.closest('.dropdown')) {
            setShowCategoryDropdown(false);
            setShowBrandDropdown(false);
          }
        };
    
        document.addEventListener('click', handleClickOutside);
    
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
        try {
            // Fetch products CSV
            const productsRes = await fetch(
            `https://docs.google.com/spreadsheets/d/e/2PACX-1vSeGR0MPoim7lt6jp5u4Whh0GogXmHNviqpvUzLR7Ff9Wu3-AWCswpMjB3MrFiuafMTovXsAUUJG1Ib/pub?gid=0&single=true&output=csv`
            );
            const productsCsv = await productsRes.text();
            const productsData = Papa.parse(productsCsv, { header: true }).data;

            // Fetch brands CSV
            const brandsRes = await fetch(
            `https://docs.google.com/spreadsheets/d/e/2PACX-1vT4oADbCGa9J-czcUjcncJNVHKGza77CQHDu536gOUIsWXlZpyIlNX7XiHp9On5zGJybzb1vlApZkkZ/pub?gid=29616083&single=true&output=csv`
            );
            const brandsCsv = await brandsRes.text();
            const brandsData = Papa.parse(brandsCsv, { header: true }).data.map(row =>
            Object.fromEntries(
                Object.entries(row).map(([key, value]) => [key.toLowerCase(), value?.toLowerCase()])
            )
            );

            setProducts(productsData);
            setBrands(brandsData);
        } catch (error) {
            console.error("Error fetching data from Google Sheets:", error);
        }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setState(true);  // Add scaling effect (shadow, border) when scrolled
            } else {
                setState(false);  // Remove scaling effect when at top of page
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleCategoryChange = (category) => {
        navigate(`/products?category=${category}`);
        setShowCategoryDropdown(false); // Close dropdown after selection
    };

    const handleBrandChange = (brand) => {
        navigate(`/products?brand=${brand}`);
        setShowBrandDropdown(false); // Close dropdown after selection
    };

    const scrollToSection = (section) => {
        
        // Check if the current path is not '/'
        if (window.location.pathname !== '/') {
          // Navigate to the homepage first
          navigate('/');
      
          // Wait for the page to load and then scroll
          setTimeout(() => {
            const element = document.getElementById(section);
            element?.scrollIntoView({ behavior: 'smooth' });
          }, 100);
        } else {
          // If already on the homepage, directly scroll to the section
          const element = document.getElementById(section);
          element?.scrollIntoView({ behavior: 'smooth' });
        }
    };
    

    const categories = Array.from(new Set(products.map((product) => product.category)));


    return (
        <nav className="bg-white w-full border-b md:border-0">
            <div className="items-center px-4 max-w-screen-xl mx-auto md:flex md:px-8">
                <div className="flex items-center justify-between py-3 md:py-5 md:block">
                        <a href="javascript:void(0)">
                            <div className="text-2xl font-bold">
                                <img className='w-32' src={'/images/glowplus-logo.png'}/>
                            </div>
                        </a>
                    <div className="md:hidden">
                        <button className="text-gray-700 outline-none p-2 rounded-md focus:border-gray-400 focus:border"
                            onClick={() => setState(!state)}
                        >
                            {
                                state ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                                    </svg>
                                )
                            }
                        </button>
                    </div>
                </div>
                <div className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${ state ? 'block' : 'hidden'}`}>
                    <ul className="justify-center items-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                        <li>
                            <button
                                className="text-lg hover:text-primary"
                                onClick={() => scrollToSection('about')}
                            >
                                <FontAwesomeIcon icon={faQuestion} /> About Glow+24
                            </button>
                            </li>
                        <li>
                        {/* Scroll to Services */}
                            <button
                                className="text-lg hover:text-primary"
                                onClick={() => scrollToSection('services')}
                            >
                                <FontAwesomeIcon icon={faStarOfLife} /> Glow Solutions
                            </button>
                        </li>
                        <li>
                            {/* Category Dropdown */}
                            <div className="relative dropdown">
                                <button
                                    onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
                                    className="text-lg hover:text-primary"
                                >
                                    <FontAwesomeIcon icon={faListAlt} /> {categorySelected || 'Categories'} <FontAwesomeIcon icon={faAngleDown} />
                                </button>
                                {showCategoryDropdown && (
                                    <div className="absolute left-0 mt-2 bg-white text-gray-800 shadow-lg rounded-md w-48 z-50">
                                        <ul>
                                            {categories.map((category) => (
                                                <li
                                                    key={category}
                                                    className="p-2 hover:bg-pastelBlue cursor-pointer"
                                                    onClick={() => handleCategoryChange(category)}
                                                >
                                                    {category}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </li>

                        <li>
                            {/* Brand Dropdown */}
                            <div className="relative dropdown">
                                <button
                                    onClick={() => setShowBrandDropdown(!showBrandDropdown)}
                                    className="text-lg hover:text-primary"
                                >
                                    <FontAwesomeIcon icon={faTags} /> {brandSelected || 'Brands'} <FontAwesomeIcon icon={faAngleDown} />
                                </button>
                                {showBrandDropdown && (
                                    <div className="absolute left-0 mt-2 bg-white text-gray-800 shadow-lg rounded-md w-48 z-50">
                                        <ul className="h-44 overflow-y-auto">
                                            {brands.map((brand) => (
                                                <li
                                                    key={brand.brand_name}
                                                    className="p-2 hover:bg-pastelBlue cursor-pointer"
                                                    onClick={() => handleBrandChange(brand.brand_name)}
                                                >
                                                    {brand.brand_name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </li>
                           
                            
                        
                    </ul>
                </div>
                <div className="hidden md:inline-block">
                    <button onClick={() => scrollToSection('contact')} className="py-2 px-4 text-white bg-secondary transition hover:bg-accentPink rounded-full shadow">
                        Contact Us
                    </button>
                </div>
            </div>
        </nav>
        
    );
};

export default Navbar;
