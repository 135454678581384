import { useEffect, useState } from 'react';
import './App.css';
import { fetchGoogleSheetData } from './utils/fetchGoogleSheet';
import ImageUploader from './ImageUploader';
import ImageCarousel from './ImageCarousel';
import ReorderImages from './ReorderImages';
import { auth } from './firebase';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import LoginPage from './LoginPage'; // Make sure to create a LoginPage component
import PrivateRoute from './PrivateRoute'; // Create the PrivateRoute component for route protection
import { onAuthStateChanged } from 'firebase/auth';
import HomePage from './HomePage';
import DashboardPage from './DashboardPage';
import ProductsPage from './ProductsPage';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return unsubscribe;
  }, []);

  return (
    <div className="min-h-screen">
      <Router>
        

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/products"
            element={
              
              <ProductsPage />
              
            }
          />
          <Route path="/login" element={
            <>
            <nav className="bg-gray-800 p-4 text-white">
            <ul className="flex space-x-4">
              <li><Link to="/">Home</Link></li>
              {user ? (
                <>
                  <li><Link to="/dashboard">Dashboard</Link></li>
                  <li><button onClick={() => auth.signOut()}>Sign Out</button></li>
                </>
              ) : (
                <li><Link to="/login">Login</Link></li>
              )}
            </ul>
          </nav>
            <LoginPage />
            </>
            
            } />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute user={user}>
                <DashboardPage user={user} />
              </PrivateRoute>
            }
          />
          
        </Routes>

        
      </Router>
    </div>
  );
}

export default App;
