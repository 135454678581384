import React, { useState } from "react";
import { storage } from "./firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

function ImageUploader() {
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
        setImage(e.target.files[0]);
        }
    };

    const handleUpload = () => {
        if (!image) return;
        setLoading(true);

        const storageRef = ref(storage, `uploads/${image.name}`);
        uploadBytes(storageRef, image).then(() => {
        getDownloadURL(storageRef).then((url) => {
            setImageUrl(url);
            setLoading(false);
        });
        });
    };

    return (
        <div className="max-w-md mx-auto my-8 p-4 border border-gray-300 rounded-lg">
        <input type="file" onChange={handleImageChange} />
        <button
            onClick={handleUpload}
            className="mt-2 p-2 bg-blue-500 text-white rounded"
            disabled={loading}
        >
            {loading ? "Uploading..." : "Upload Image"}
        </button>
        {imageUrl && <img src={imageUrl} alt="Uploaded" className="mt-4" />}
        </div>
    );
}

export default ImageUploader;
